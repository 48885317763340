import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import {
  OwnersResourcesLandingViewModel,
  VehicleLookupState,
} from './models/owners-landing-model';
import { isSubaruVin, validateVIN } from '~/common/utils/carDetailValidator';
import PopupModalNative from './components/PopupModal';

import { Button, TooltipDisclaimer } from '~/common/components/ui-elements';
import {
  createInfomediaStore,
  submitManualData,
} from './components/InfomediaStore';

const OwnersResourcesLandingPage = (props: OwnersResourcesLandingViewModel) => {
  const store = createInfomediaStore();

  const [state, setState] = useState<VehicleLookupState>({
    vinNumber: '',
    vinNumberError: false,
    vinNumberErrorString: '',
    submitDisabled: false,
    recaptchaToken: undefined,
    showLoader: false,
    recaptchaServerFailed: false,
    showVinPopup: false,
    vinNotFound: false,
  });

  const validateRecaptcha = (recaptchaToken: string | undefined) => {
    setState((prevState) => ({ ...prevState, recaptchaToken }));
  };

  const closeVinPopup = () => {
    setState((prevState) => ({ ...prevState, showVinPopup: false }));
  };

  const handleVINChange = (value: string) => {
    const isError = validateVIN(value);
    setState((prevState) => ({
      ...prevState,
      vinNumberError: isError !== '',
      vinNumberErrorString: isError,
      vinNumber: value,
      submitDisabled: isError !== '',
      vinNotFound: false,
    }));
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!state.vinNumber) return;
    setState((prevState) => ({
      ...prevState,
      showLoader: true,
      submitDisabled: true,
      vinNotFound: false,
    }));
    findCarByVin(state.vinNumber);
  };

  const findCarByVin = async (vin: string) => {
    if (isSubaruVin(vin)) {
      try {
        const result = await store.findCarByVin(vin);
        submitError(result.status, result.data);
      } catch (error) {
        console.error('Error during VIN submission:', error);
      }
    } else {
      console.log('Invalid VIN');
      setState((prevState) => ({
        ...prevState,
        vinNotFound: true,
        submitDisabled: false,
      }));
    }
  };

  const submitError = (status: number, data: any) => {
    if (status === 200) {
      let vehicleLine;
      if (data.model.vehicleLine === null) {
        const description = data.model.description;
        vehicleLine = description ? description.split(' ')[0] : '';
      } else {
        vehicleLine = data.model.vehicleLine;
      }
      submitManualData(data, vehicleLine);
    } else {
      setState((prevState) => ({
        ...prevState,
        vinNotFound: true,
        submitDisabled: false,
      }));
    }
  };

  const {
    vinNumberError,
    vinNumberErrorString,
    submitDisabled,
    recaptchaToken,
    showVinPopup,
    recaptchaServerFailed,
    vinNotFound,
  } = state;

  useEffect(() => {
    window['validateRecallRecaptcha'] = validateRecaptcha;
    window['recaptchaExpiredCallback'] = () => {
      setState((prevState) => ({
        ...prevState,
        recaptchaToken: undefined,
      }));
    };
  }, []);

  return (
    <div id="owners-resources" className={styles.containerRow}>
      <div className={styles.contentHeader}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: props.headerTitle as string }}
        />
        {props.tooltipDisclaimer && (
          <TooltipDisclaimer disclaimer={props.tooltipDisclaimer} />
        )}
      </div>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: props.description as string }}
      />
      <div className={styles.vinDetail}>
        <span
          dangerouslySetInnerHTML={{ __html: props.subheading as string }}
        />
        {props.subheadingTooltip && (
          <TooltipDisclaimer disclaimer={props.subheadingTooltip} />
        )}
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.vinWrapper}>
            Search by VIN{' '}
            <a
              className={styles.btnText}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  showVinPopup: true,
                }))
              }
            >
              Where&apos;s my VIN?
            </a>
          </div>
          <div className={styles.fieldSpacing}>
            <input
              className={`${styles.vinInputClass} ${
                vinNumberError ? styles.errorInput : ''
              }`}
              value={state.vinNumber}
              onChange={(e) => handleVINChange(e.target.value)}
            />
          </div>
          {vinNumberError && (
            <p className={styles.errorText}>{vinNumberErrorString}</p>
          )}
          {vinNotFound && (
            <p className={styles.errorText}>
              The VIN entered is not valid or could not be found. Please try
              again, or contact Subaru Customer Relations Team via &nbsp;
              <a href="mailto:feedback@subaru.com.au">feedback@subaru.com.au</a>
              . Please note – &apos;Owners Resources&apos; are only available
              for certain Model Year 2024 vehicles and newer.
            </p>
          )}
          <div className={styles.recaptchaWrapper}>
            <div
              className="g-recaptcha"
              data-sitekey="6LcD-CQjAAAAAP6Ey2v2uT1Tb1D3OEOiFPLS0Zr1"
              data-expired-callback="validateRecallRecaptcha"
              data-callback="validateRecallRecaptcha"
            />
            {recaptchaServerFailed && (
              <p className={styles.recaptchaError}>
                Failed validating Google ReCaptcha. Please try again.
              </p>
            )}
          </div>
          <div className={styles.submitBtnWrapper}>
            <Button
              type="primary"
              disabled={submitDisabled || !recaptchaToken}
              onClick={onSubmit}
            >
              View
            </Button>
          </div>
        </div>
      </div>
      {showVinPopup && (
        <PopupModalNative
          content={props.popupModalContent}
          isVisible={showVinPopup}
          close={closeVinPopup}
        />
      )}
    </div>
  );
};

export default OwnersResourcesLandingPage;
